import * as React from 'react';
import {Create, SimpleForm, TextInput, BooleanInput, SelectInput, required, email, minLength, regex} from 'react-admin';
import {Box, Grid} from '@mui/material';

const validatePhone = regex(/^((((0{2}?)|(\+){1})46)|0)7[\d]{8}/, 'Must be a valid phone number format');

export const UserCreate = () => {
  return (
    <Create>
      <SimpleForm>
        <BooleanInput source="isActive" defaultValue={true} />

        <Grid container width="100%" spacing={1}>
          <Grid item xs={8}>
            <Box display="flex">
              <Box flex={1} mr={1}>
                <TextInput source="firstName" fullWidth validate={[required()]} />
              </Box>
              <Box flex={1} ml={1} mr={1}>
                <TextInput source="lastName" fullWidth validate={[required()]} />
              </Box>
              <Box flex={1} ml={1}>
                <SelectInput
                  source="role"
                  fullWidth
                  validate={[required()]}
                  defaultValue={'user'}
                  choices={[
                    {id: 'admin', name: 'resources.users.fields.admin'},
                    {id: 'permitSupervisor', name: 'resources.users.fields.permitSupervisor'},
                    {id: 'productionOperator', name: 'resources.users.fields.productionOperator'},
                    {id: 'maintenanceTechnician', name: 'resources.users.fields.maintenanceTechnician'},
                  ]}
                />
              </Box>
            </Box>
            <Box display="flex">
              <Box flex={2} mr={1}>
                <TextInput source="phone" fullWidth validate={[required(), validatePhone]} />
              </Box>
              <Box flex={1}></Box>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex">
              <Box flex={1} mr={1}>
                <TextInput source="email" fullWidth validate={[required(), email()]} />
              </Box>
              <Box flex={1} ml={1}>
                <TextInput source="password" fullWidth validate={[minLength(8)]} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </SimpleForm>
    </Create>
  );
};
