import {makeStyles} from '@mui/styles';
import {Sidebar} from 'react-admin';
import MenuFooter from '../menuFooter/menuFooter.component';

const useStyles = makeStyles(theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column nowrap',
    justifyContent: 'space-between',
  },
}));

const CustomSidebar = (props: any) => {
  const classes = useStyles(props);

  return (
    <Sidebar {...props} size={200}>
      <div className={classes.wrapper}>
        {props.children}
        <MenuFooter />
      </div>
    </Sidebar>
  );
};

export default CustomSidebar;
