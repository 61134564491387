import * as React from 'react';
import {Fragment} from 'react';
import {BulkDeleteWithConfirmButton, Filter, NullableBooleanInput, SearchInput} from 'react-admin';
import {List, Datagrid, TextField, DateField} from 'react-admin';
import {OperationShow} from './operation.show.component';

const OperationBulkActionButtons = (props: any) => {
  const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);

  React.useEffect(() => {
    setSelectedIds(props.selectedIds);
  }, [props.selectedIds, selectedIds]);

  return (
    <Fragment>
      <BulkDeleteWithConfirmButton {...props} />
    </Fragment>
  );
};

const OperationFilter = props => {
  return (
    <Filter {...props}>
      <SearchInput variant={'standard'} source="q" alwaysOn resettable />
    </Filter>
  );
};

export const OperationList = () => {
  return (
    <List sort={{field: 'title', order: 'ASC'}} exporter={false} filters={<OperationFilter />}>
      <Datagrid className="oddRow" bulkActionButtons={<OperationBulkActionButtons />} expand={OperationShow}>
        <TextField source="title" />
        <TextField source="reference" />
        <DateField source="createdAt" options={{day: '2-digit', month: 'short', year: 'numeric'}} />
        <DateField source="updatedAt" options={{day: '2-digit', month: 'short', year: 'numeric'}} />
      </Datagrid>
    </List>
  );
};
