import * as React from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  useRecordContext,
  useResourceContext,
  ReferenceInput,
  SelectInput,
  required,
  DateInput,
} from 'react-admin';
import {CustomToolbar} from '../../components/customtoolbar/customToolbar.component';
import {Box, Grid} from '@mui/material';

export const ActionEdit = () => {
  const resource = useResourceContext();
  const record = useRecordContext();

  return (
    <Edit resource={resource} id={record?.id}>
      <SimpleForm toolbar={<CustomToolbar />}>
        <Grid container width="100%" spacing={1}>
          <Grid item xs={8}>
            <Box display="flex">
              <Box flex={1} mr={1}>
                <TextInput source="title" fullWidth />
              </Box>
              <Box flex={1} ml={1}>
                <ReferenceInput source="riskId" reference="risks">
                  <SelectInput optionText="title" validate={[required()]} />
                </ReferenceInput>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={8}>
            <Box display="flex">
              <Box flex={1} mr={1}>
                <DateInput source="createdAt" disabled fullWidth />
              </Box>
              <Box flex={1} ml={1}>
                <DateInput source="updatedAt" disabled fullWidth />
              </Box>
            </Box>
          </Grid>
        </Grid>
        <BooleanInput source="startActive" />
      </SimpleForm>
    </Edit>
  );
};
