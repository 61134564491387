import * as React from 'react';
import {Layout, AppBar} from 'react-admin';
import {UserMenu} from '../components/userMenu/userMenu.component';
import CustomSidebar from '../components/customSidebar/customSidebar.component';
import Menu from '../components/menu/menu.component';

const CustomAppBar = (props: any) => <AppBar {...props} userMenu={<UserMenu />} />;

export const CustomLayout = (props: any) => (
  <Layout {...props} appBar={CustomAppBar} sidebar={CustomSidebar} menu={Menu} />
);

export default CustomLayout;
