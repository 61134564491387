import * as React from 'react';
import {useTranslate} from 'react-admin';
import PropTypes from 'prop-types';
import DashboardIcon from '@mui/icons-material/Dashboard';

import CustomMenuItemLink from '../customMenuItemLink/customMenuItemLink.component';

const CustomDashboardMenuItem = (props: CustomDashboardMenuItemProps) => {
  const {locale, ...rest} = props;
  const translate = useTranslate();

  return (
    <CustomMenuItemLink
      to="/"
      primaryText={translate('resources.dashboard.name')}
      leftIcon={<DashboardIcon />}
      // exact
      {...rest}
    />
  );
};

export interface CustomDashboardMenuItemProps {
  classes?: object;
  locale?: string;
  onClick?: () => void;
  dense?: boolean;
  /**
   * @deprecated
   */
  sidebarIsOpen?: boolean;
}

CustomDashboardMenuItem.propTypes = {
  classes: PropTypes.object,
  locale: PropTypes.string,
  onClick: PropTypes.func,
  dense: PropTypes.bool,
  sidebarIsOpen: PropTypes.bool,
};

export default CustomDashboardMenuItem;
