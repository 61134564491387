import * as React from 'react';
import {Card, CardContent} from '@mui/material';
import {Title} from 'react-admin';

export const Dashboard = ({permissions}) => {
  return (
    <Card>
      <Title title="Dashboard" />
      <CardContent>
        <h1>Dashboard</h1>

        {permissions === 'editor' ? <p>Welcome editor!</p> : null}
        {permissions === 'admin' ? <p>Welcome admin!</p> : null}
      </CardContent>
    </Card>
  );
};
