//import { TranslationMessages } from 'ra-core';

export const languageSwedish = {
  resources: {
    dashboard: {
      name: 'Startsida',
    },
    users: {
      name: 'Användare',
      fields: {
        firstName: 'Förnamn',
        lastName: 'Efternamn',
        email: 'E-post',
        phone: 'Telefon',
        role: 'Roll',
        password: 'Lösenord',
        isActive: 'Aktiv',
        createdAt: 'Skapad',
        updatedAt: 'Uppdaterades',
        admin: 'Administratör',
        permitSupervisor: 'Tillståndsgivare',
        productionOperator: 'Driftpersonal',
        maintenanceTechnician: 'Utförare',
      },
    },
    operations: {
      name: 'Operationer',
      fields: {
        title: 'Titel',
        reference: 'Operationens ID',
        createdAt: 'Skapad',
        updatedAt: 'Uppdaterades',
        pendingWorkPermit: 'Väntar på arbetstillstånd',
        pendingDocument: 'Väntar på utförare',
        technicianRiskAssessment: 'Utförarens riskbedömning',
        technicianWorkReport: 'Utförarens avrapportering',
      },
    },
    risktemplates: {
      name: 'Riskmall',
      meta: 'Metadata',
      fields: {
        title: 'Titel',
        createdAt: 'Skapad',
        updatedAt: 'Uppdaterades',
      },
    },
    risks: {
      name: 'Risker',
      fields: {
        title: 'Titel',
        startActive: 'Förvald',
        riskTemplateId: 'Tillhör mall',
      },
    },
    actions: {
      name: 'Åtgärder',
      fields: {
        title: 'Titel',
        startActive: 'Förvald',
        riskId: 'Tillhör risk',
      },
    },
  },
  ra: {
    configurable: {
      customize: 'Anpassa',
    },
    page: {
      create: 'Skapa %{name}',
      edit: '%{name} #%{id}',
      error: 'Något gick fel',
      list: '%{name}',
      loading: 'Laddar',
      not_found: 'Hittades inte',
      show: '%{name} #%{id}',
      empty: 'Inget %{name} ännu.',
      invite: 'Vill du bjuda in någon?',
    },
    action: {
      add_filter: 'Lägg till filter',
      add: 'Lägg till',
      back: 'Tillbaka',
      bulk_actions: '1 vald |||| %{smart_count} valda',
      cancel: 'Avbryt',
      clear_input_value: 'Rensa',
      clone: 'Klona',
      confirm: 'Bekräfta',
      create: 'Lägg till',
      create_item: 'nytt %{item}',
      delete: 'Radera',
      edit: 'Editera',
      export: 'Exportera',
      list: 'Lista',
      refresh: 'Uppdatera',
      remove_filter: 'Ta bort filter',
      remove: 'Ta bort',
      save: 'Spara',
      search: 'Sök',
      show: 'Visa',
      sort: 'Sortera',
      undo: 'Ångra',
      unselect: 'Avmarkera',
      expand: 'Expandera',
      close: 'Stäng',
      open_menu: 'Öppna meny',
      close_menu: 'Stäng meny',
      update: 'Uppdatera',
      move_up: 'Flytta upp',
      move_down: 'Flytta ner',
    },
    user: {
      setStatus: 'Välj status',
      selectOne: 'Välj en',
      active: 'Aktiv',
      isActive: 'Är aktiv',
      disabled: 'Ej aktiv',
      confirmationStatus: 'Uppdatera användarstatus',
      confirmationContent: 'Är du säker på att du vill ändra status till "%{isActive}" för dessa användare?',
      disallowedForCurrentAdmin: 'Kan inte modifiera inloggad administratör',
    },
    boolean: {
      true: 'Ja',
      false: 'Nej',
      null: ' ',
    },
    input: {
      file: {
        upload_several: 'Släpp några filer för att ladda upp, eller klicka för att välja en.',
        upload_single: 'Släpp en fil att ladda upp, eller klicka för att välja den.',
      },
      image: {
        upload_several: 'Släpp några bilder för att ladda upp, eller klicka för att välja en.',
        upload_single: 'Släpp en bild för att ladda upp, eller klicka för att välja den.',
      },
      references: {
        all_missing: 'Det gick inte att hitta referensdata.',
        many_missing: 'Minst en av de associerade referenserna verkar inte längre vara tillgängliga.',
        single_missing: 'Tillhörande referens verkar inte längre vara tillgänglig.',
      },
      password: {
        toggle_visible: 'Göm lösenord',
        toggle_hidden: 'Visa lösenord',
      },
    },
    message: {
      about: 'Om',
      are_you_sure: 'Är du säker?',
      bulk_delete_content:
        'Är du säker på att du vill ta bort detta %{name}? |||| Är du säker på att du vill ta bort dessa %{smart_count} objekt?',
      bulk_delete_title: 'Radera %{name} |||| Radera %{smart_count} %{name}',
      bulk_update_content:
        'Är du säker på att du vill uppdatera detta %{name}? |||| Är du säker på att du vill uppdatera dessa %{smart_count} objekt?',
      bulk_update_title: 'Uppdatera %{name} |||| Uppdatera %{smart_count} %{name}',
      delete_content: 'Är du säker på att du vill ta bort det här objektet?',
      delete_title: 'Radera %{name} #%{id}',
      details: 'Detaljer',
      error: 'Ett klientfel inträffade och din begäran kunde inte slutföras.',
      invalid_form: 'Blanketten är ogiltig. Kontrollera om det finns fel',
      loading: 'Sidan laddas, bara ett ögonblick tack',
      no: 'Nej',
      not_found: 'Antingen skrev du fel URL, eller så följde du en dålig länk.',
      yes: 'Ja',
      unsaved_changes: 'Vissa av dina ändringar sparades inte. Är du säker på att du vill ignorera dem?',
    },
    navigation: {
      no_results: 'Inga resultat funna',
      no_more_results: 'Sidnumret %{page} är utanför gränserna. Prova föregående sida.',
      page_out_of_boundaries: 'Sidnumret %{page} är utanför gränserna',
      page_out_from_end: 'Kan inte gå efter sista sidan',
      page_out_from_begin: 'Kan inte gå före sida 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} av %{total}',
      page_rows_per_page: 'Rader per sida:',
      next: 'Nästa',
      previous: 'Föregående',
      skip_nav: 'Hoppa till innehåll',
    },
    sort: {
      sort_by: 'Sortera efter %{field} %{order}',
      ASC: 'stigande',
      DESC: 'fallande',
    },
    auth: {
      auth_check_error: 'Var vänlig logga in för att fortsätta',
      user_menu: 'Profil',
      username: 'Användarnamn',
      password: 'Lösenord',
      sign_in: 'Logga in',
      sign_in_error: 'Autentiseringen misslyckades, försök igen',
      logout: 'Logga ut',
    },
    notification: {
      updated: 'Element uppdaterat |||| %{smart_count} element uppdaterade',
      created: 'Element skapat',
      deleted: 'Elementet har tagits bort |||| %{smart_count} element raderade',
      bad_item: 'Fel element',
      item_doesnt_exist: 'Elementet existerar inte',
      http_error: 'Serverkommunikationsfel',
      data_provider_error: 'dataProvider -fel. Kontrollera konsolen för mer information.',
      i18n_error: 'Det går inte att läsa in översättningarna för det angivna språket',
      canceled: 'Åtgärd avbruten',
      logged_out: 'Din session har avslutats. Anslut igen.',
      not_authorized: 'Du har inte behörighet att komma åt den här resursen.',
    },
    validation: {
      required: 'Nödvändig',
      minLength: 'Måste vara %{min} tecken åtminstone',
      maxLength: 'Måste vara %{max} tecken eller färre',
      minValue: 'Måste vara åtminstone %{min}',
      maxValue: 'Måste vara %{max} eller färre',
      number: 'Måste vara ett nummer',
      email: 'Måste vara ett giltigt mejl',
      oneOf: 'Måste vara en av: %{options}',
      regex: 'Måste matcha ett visst format (regexp): %{pattern}',
    },
  },
};

export default languageSwedish;
