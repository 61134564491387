import {FC, ReactNode} from 'react';
import PropTypes from 'prop-types';
import {useTranslate, usePermissions, useSidebarState} from 'react-admin';
// @ts-ignore
import classnames from 'classnames';
import {makeStyles} from '@mui/styles';
import {useMediaQuery, Theme, List} from '@mui/material';
import lodashGet from 'lodash/get';
import CustomMenuItemLink from '../customMenuItemLink/customMenuItemLink.component';
import CustomDashboardMenuItem from '../customDashboardMenuItem/customDashboardMenuItem.component';

// Icons
import UserIcon from '@mui/icons-material/People';
import OperationIcon from '@mui/icons-material/Drafts';
import SubMenu from '../subMenu/subMenu.component';

export const MENU_WIDTH = 240;
export const CLOSED_MENU_WIDTH = 55;

const useStyles = makeStyles(
  theme => ({
    main: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      marginTop: '0.5em',
      [theme.breakpoints.only('xs')]: {
        marginTop: 0,
      },
      [theme.breakpoints.up('md')]: {
        marginTop: '1.5em',
      },
    },
    open: {
      width: lodashGet(theme, 'menu.width', MENU_WIDTH),
    },
    closed: {
      width: lodashGet(theme, 'menu.closedWidth', CLOSED_MENU_WIDTH),
    },
  }),
  {name: 'RaMenu'},
);

const Menu: FC<MenuProps> = props => {
  const {classes: classesOverride, className, dense, hasDashboard, ...rest} = props;

  const classes = useStyles(props);
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const [open] = useSidebarState();
  const {permissions} = usePermissions();

  const translate = useTranslate();

  return (
    <List
      className={classnames(
        classes.main,
        {
          [classes.open]: open,
          [classes.closed]: !open,
        },
        className,
      )}
      component="nav"
      {...rest}>
      {hasDashboard && <CustomDashboardMenuItem dense={dense} />}
      {permissions === 'admin' && (
        <CustomMenuItemLink to="/users" primaryText={translate('resources.users.name')} leftIcon={<UserIcon />} />
      )}
      {permissions === 'admin' && (
        <CustomMenuItemLink
          to="/operations"
          primaryText={translate('resources.operations.name')}
          leftIcon={<OperationIcon />}
        />
      )}
      {permissions === 'admin' && (
        <SubMenu
          text={translate('resources.risktemplates.name')}
          icon={<OperationIcon />}
          links={[
            {text: 'resources.risktemplates.meta', link: '/riskTemplates/1', icon: <OperationIcon />},
            {text: 'resources.risks.name', link: '/risks', icon: <OperationIcon />},
            {text: 'resources.actions.name', link: '/actions', icon: <OperationIcon />},
          ]}
          dense={dense}
          sidebarIsOpen={open}
          {...props}
        />
      )}
      {isXSmall}
    </List>
  );
};

export interface MenuProps {
  classes?: object;
  className?: string;
  dense?: boolean;
  hasDashboard?: boolean;
  logout?: ReactNode;
}

Menu.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.string,
  dense: PropTypes.bool,
  hasDashboard: PropTypes.bool,
  logout: PropTypes.element,
};

Menu.defaultProps = {};

export default Menu;
