import * as React from 'react';
import {CustomRoutes, Resource} from 'react-admin';
import {Route} from 'react-router-dom';

// User
import {UserList} from './resources/user/user.list.component';
import {UserEdit} from './resources/user/user.edit.component';
import {UserCreate} from './resources/user/user.create.component';

// Operation
import {OperationList} from './resources/operation/operation.list.component';

// Risk Template
import {RiskTemplateEdit} from './resources/riskTemplate/riskTemplate.edit.component';

// Risk
import {RiskList} from './resources/risks/risk.list.component';
import {RiskEdit} from './resources/risks/risk.edit.component';
import {RiskCreate} from './resources/risks/risk.create.component';

// Action
import {ActionList} from './resources/actions/action.list.component';
import {ActionEdit} from './resources/actions/action.edit.component';
import {ActionCreate} from './resources/actions/action.create.component';

// TODO: Implement updated media library and related resources

// Custom Pages
import {HelpPage} from './pages/help.page';

export const routes = (permissions: string) => (
  <>
    <CustomRoutes>
      <Route path="/help" element={<HelpPage />} />,
    </CustomRoutes>

    {permissions === 'admin' ? <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} /> : null}
    {permissions === 'admin' ? <Resource name="operations" list={OperationList} /> : null}
    {permissions === 'admin' ? <Resource name="risktemplates" edit={RiskTemplateEdit} /> : null}
    {permissions === 'admin' ? <Resource name="risks" list={RiskList} edit={RiskEdit} create={RiskCreate} /> : null}
    {permissions === 'admin' ? (
      <Resource name="actions" list={ActionList} edit={ActionEdit} create={ActionCreate} />
    ) : null}
  </>
);

export default routes;
