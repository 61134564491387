import * as React from 'react';
import {List, Datagrid, TextField, BooleanField, ReferenceField} from 'react-admin';
import {ActionEdit} from './action.edit.component';

export const ActionList = () => {
  return (
    <List sort={{field: 'id', order: 'ASC'}} exporter={false}>
      <Datagrid className="oddRow" rowClick="edit" expand={ActionEdit}>
        <TextField source="title" />
        <ReferenceField source="riskId" reference="risks" link={false}>
          <TextField source="title" />
        </ReferenceField>
        <BooleanField source="startActive" />
      </Datagrid>
    </List>
  );
};
