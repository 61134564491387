import * as React from 'react';
import {useState} from 'react';
import PropTypes from 'prop-types';
import {Field, withTypes} from 'react-final-form';
import {useLocation} from 'react-router-dom';

import {Avatar, Button, Card, CardActions, CircularProgress, TextField} from '@mui/material';
import {createTheme} from '@mui/material/styles';
import {makeStyles, ThemeProvider} from '@mui/styles';
import LockIcon from '@mui/icons-material/Lock';
import {Notification, useTranslate, useLogin, useNotify} from 'react-admin';

import {dvzTheme} from './../theme';
import {ReactComponent as DvzLogo} from '../dvzLogo.svg';
import bgImage from '../bgLogin.jpg';

const font = {
  family: '"IBM Plex Sans", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Arial", "sans-serif"',
};

const useStyles = makeStyles(theme => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#3cc',
    backgroundImage: `url("${bgImage}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center bottom',
  },
  card: {
    minWidth: 350,
    marginTop: '2em',
    textAlign: 'center',
    padding: '1rem 1.5rem',
  },
  avatar: {
    backgroundColor: theme.palette.grey[300],
    width: '3.5rem',
    height: '3.5rem',
    margin: '0 auto 1rem',
  },
  icon: {
    color: theme.palette.grey[500],
  },
  adminlabel: {
    marginBottom: '2rem',
    fontSize: '0.9em',
    color: theme.palette.grey[600],
    fontWeight: 'normal',
    fontFamily: font.family,
  },
  hint: {
    marginTop: '-0.25rem',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.grey[500],
    fontWeight: 500,
    textTransform: 'uppercase',
    fontFamily: font.family,
  },
  form: {
    padding: '0 1em 1em 1em',
  },
  MuiAvatar: {
    root: {
      width: 300,
    },
  },
  input: {
    marginTop: '1em',
    fontFamily: font.family,
  },
  label: {
    fontFamily: font.family,
  },
  MuiFormHelperText: {
    fontFamily: font.family,
  },
  actions: {
    padding: '0 1em 1em 1em',
  },
  logo: {
    margin: '1rem auto 0',
  },
}));

const renderInput = ({
  meta: {touched, error} = {touched: false, error: undefined},
  input: {...inputProps},
  ...props
}) => <TextField error={!!(touched && error)} helperText={touched && error} {...inputProps} {...props} fullWidth />;

interface FormValues {
  username?: string;
  password?: string;
}

const {Form} = withTypes<FormValues>();

const Login = () => {
  const [loading, setLoading] = useState(false);
  const translate = useTranslate();
  const classes = useStyles();
  const notify = useNotify();
  const login = useLogin();
  const location = useLocation();

  const handleSubmit = (auth: FormValues) => {
    setLoading(true);
    login(auth, location.state ? location.state.nextPathname : '/').catch((error: Error) => {
      setLoading(false);
      notify(
        typeof error === 'string'
          ? error
          : typeof error === 'undefined' || !error.message
            ? 'ra.auth.sign_in_error'
            : error.message,
        {type: 'warning'},
      );
    });
  };

  const validate = (values: FormValues) => {
    const errors: FormValues = {};
    if (!values.username) {
      errors.username = translate('ra.validation.required');
    }
    if (!values.password) {
      errors.password = translate('ra.validation.required');
    }
    return errors;
  };

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({handleSubmit}) => (
        <form onSubmit={handleSubmit} noValidate>
          <div className={classes.main}>
            <Card className={classes.card}>
              <DvzLogo width="240" height="100" className={classes.logo} />
              <div className={classes.adminlabel}>Devize Admin</div>
              <Avatar className={classes.avatar}>
                <LockIcon className={classes.icon} fontSize="large" />
              </Avatar>
              <div className={classes.hint}>Permitify Admin</div>
              <div className={classes.form}>
                <div className={classes.input}>
                  <Field
                    autoFocus
                    name="username"
                    variant="standard"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('ra.auth.username')}
                    disabled={loading}
                  />
                </div>
                <div className={classes.input}>
                  <Field
                    name="password"
                    variant="standard"
                    // @ts-ignore
                    component={renderInput}
                    label={translate('ra.auth.password')}
                    type="password"
                    disabled={loading}
                  />
                </div>
              </div>
              <CardActions className={classes.actions}>
                <Button variant="contained" type="submit" color="primary" disabled={loading} fullWidth>
                  {loading && <CircularProgress size={25} thickness={2} />}
                  {translate('ra.auth.sign_in')}
                </Button>
              </CardActions>
            </Card>
            <Notification />
          </div>
        </form>
      )}
    />
  );
};

Login.propTypes = {
  authProvider: PropTypes.func,
  previousRoute: PropTypes.string,
};

// We need to put the ThemeProvider decoration in another component
// Because otherwise the useStyles() hook used in Login won't get
// the right theme
const LoginWithTheme = (props: any) => (
  <ThemeProvider theme={createTheme(dvzTheme)}>
    <Login {...props} />
  </ThemeProvider>
);

export default LoginWithTheme;
