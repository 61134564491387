import inMemoryJWT from './inMemoryJWT';

const apiAuth = process.env.REACT_APP_API_AUTH;

const authProvider = {
  login: ({username, password}) => {
    const request = new Request(`${apiAuth}/login`, {
      method: 'POST',
      body: JSON.stringify({email: username, password}),
      headers: new Headers({'Content-Type': 'application/json'}),
      credentials: 'include',
    });
    inMemoryJWT.setRefreshTokenEndpoint(`${apiAuth}/refresh-token-admin`);
    return fetch(request)
      .then(response => {
        if (!response.status || response.status < 200 || response.status >= 300) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(({tokens, user}) => {
        if (user) {
          localStorage.setItem('permissions', user.role);
          localStorage.setItem('auth', JSON.stringify({id: user.id, fullName: `${user.firstName} ${user.lastName}`}));
        }
        return inMemoryJWT.setToken(tokens.accessToken);
      });
  },

  getIdentity: () => {
    try {
      const userString = localStorage.getItem('auth');
      if (!userString) {
        return Promise.reject();
      }
      const {id, fullName, avatar} = JSON.parse(userString);
      return Promise.resolve({id, fullName, avatar});
    } catch (error) {
      return Promise.reject(error);
    }
  },

  logout: () => {
    const request = new Request(`${apiAuth}/logout`, {
      method: 'POST',
      body: JSON.stringify({refreshToken: inMemoryJWT.getToken()}),
      headers: new Headers({'Content-Type': 'application/json'}),
      credentials: 'include',
    });
    inMemoryJWT.ereaseToken();
    localStorage.removeItem('auth');

    return fetch(request)
      .then(() => '/login')
      .catch(() => '/login');
  },

  checkAuth: () => {
    const token = inMemoryJWT.getToken();
    if (!token) {
      inMemoryJWT.setRefreshTokenEndpoint(`${apiAuth}/refresh-token-admin`);
      inMemoryJWT
        .getRefreshedToken()
        .then(gotFreshToken => {
          if (gotFreshToken) {
            return Promise.resolve();
          }
          Promise.reject({redirectTo: '/login'});
        })
        .catch(error => Promise.reject({redirectTo: '/login'}));
    }
    return Promise.resolve();
  },

  checkError: error => {
    if (!error?.status || error.status === 401 || error.status === 403) {
      inMemoryJWT.ereaseToken();
      return Promise.reject('Unauthorized access');
    }
    return Promise.resolve();
  },

  getPermissions: () => {
    // return Promise.resolve('admin');
    const role = localStorage.getItem('permissions');
    return role ? Promise.resolve(role) : Promise.reject('No permission');
  },
};

export default authProvider;
