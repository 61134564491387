import React from 'react';
import {Toolbar, SaveButton, DeleteWithUndoButton, ToolbarProps} from 'react-admin';

interface CustomToolbarProps extends ToolbarProps {
  noDelete?: boolean;
}

export const CustomToolbar = ({noDelete, ...props}: CustomToolbarProps) => {
  return (
    <Toolbar {...props}>
      <SaveButton />
      {!noDelete && <DeleteWithUndoButton />}
    </Toolbar>
  );
};
