const inMemoryJWTManager = () => {
  let inMemoryJWT: string | null = null;
  let isRefreshing: Promise<any> | null = null;
  let logoutEventName = 'ra-logout';
  let refreshEndpoint = '/refresh-token-admin';
  let refreshTimeOutId: number | undefined;

  const setLogoutEventName = name => (logoutEventName = name);
  const setRefreshTokenEndpoint = endpoint => (refreshEndpoint = endpoint);

  const abordRefreshToken = () => {
    if (refreshTimeOutId) {
      window.clearTimeout(refreshTimeOutId);
    }
  };

  // This countdown feature is used to renew the JWT before it's no longer valid
  // in a way that is transparent to the user.
  const refreshToken = () => {
    abordRefreshToken();
    refreshTimeOutId = window.setTimeout(
      getRefreshedToken,
      1000 * 5 * 60, // Every 5 minutes
    );
  };

  const waitForTokenRefresh = (): any => {
    if (!isRefreshing) {
      return Promise.resolve();
    }
    return isRefreshing.then(() => {
      isRefreshing = null;
      return true;
    });
  };

  // The method make a call to the refresh-token endpoint
  // If there is a valid cookie, the endpoint will set a fresh jwt in memory.
  const getRefreshedToken = () => {
    if (isRefreshing) {
      return isRefreshing;
    }
    const request = new Request(refreshEndpoint, {
      method: 'POST',
      headers: new Headers({'Content-Type': 'application/json'}),
      credentials: 'include',
    });

    isRefreshing = fetch(request)
      .then(response => {
        if (!response.status || response.status !== 200) {
          ereaseToken();
          global.console.log('Token renewal failure');
          return {token: null};
        }
        return response.json();
      })
      .then(({tokens}) => {
        if (tokens) {
          setToken(tokens.accessToken);
          return true;
        }
        ereaseToken();
        return false;
      });

    return isRefreshing;
  };

  const getToken = () => inMemoryJWT;

  const setToken = (token: string) => {
    inMemoryJWT = token;
    refreshToken();
    return true;
  };

  const ereaseToken = () => {
    inMemoryJWT = null;
    abordRefreshToken();
    window.localStorage.setItem(logoutEventName, `${Date.now()}`);
    return true;
  };

  // This listener will allow to disconnect a session of ra started in another tab
  window.addEventListener('storage', event => {
    if (event.key === logoutEventName) {
      inMemoryJWT = null;
    }
  });

  return {
    ereaseToken,
    getRefreshedToken,
    getToken,
    setLogoutEventName,
    setRefreshTokenEndpoint,
    setToken,
    waitForTokenRefresh,
  };
};

export default inMemoryJWTManager();
