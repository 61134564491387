import * as React from 'react';
import {List, Datagrid, TextField, BooleanField, ReferenceField} from 'react-admin';
import {RiskEdit} from './risk.edit.component';

export const RiskList = () => {
  return (
    <List sort={{field: 'id', order: 'ASC'}} exporter={false}>
      <Datagrid className="oddRow" rowClick="edit" expand={RiskEdit}>
        <TextField source="title" />
        <ReferenceField source="riskTemplateId" reference="riskTemplates" link={false}>
          <TextField source="title" />
        </ReferenceField>
        <BooleanField source="startActive" />
      </Datagrid>
    </List>
  );
};
