import {MenuItemLink} from 'react-admin';
// @ts-ignore
import {makeStyles} from '@mui/styles';

const useStylesMenuLink = makeStyles(
  theme => ({
    root: {
      '&& .MuiListItemIcon-root': {
        color: 'rgba(255, 255, 255, 0.5)',
      },
      color: '#fff',
      '&:hover': {
        color: theme.palette.secondary.main,
        '&& .MuiListItemIcon-root': {
          color: theme.palette.secondary.main,
        },
      },
    },
    active: {
      color: theme.palette.secondary.main,
      fontWeight: 'bold',
      '&& .MuiListItemIcon-root': {
        color: theme.palette.secondary.main,
      },
    },
  }),
  {name: 'CustomMenuItemLink'},
);

const CustomMenuItemLink = (props: any) => {
  const classes = useStylesMenuLink(props);

  return <MenuItemLink {...props} className={[classes.root, props.className].join(' ')} />;
};

export default CustomMenuItemLink;
