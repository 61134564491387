import * as React from 'react';
import {Fragment} from 'react';
import {Select, InputLabel, MenuItem, FormControl} from '@mui/material';
import {
  BulkDeleteWithConfirmButton,
  Confirm,
  Filter,
  NullableBooleanInput,
  useRefresh,
  useNotify,
  useUpdateMany,
  useUnselectAll,
  SearchInput,
  FunctionField,
} from 'react-admin';
import {List, Datagrid, TextField, EmailField, BooleanField, DateField} from 'react-admin';
import {UserEdit} from './user.edit.component';
import {useGetIdentity, useTranslate} from 'ra-core';

const UserBulkActionButtons = (props: any) => {
  const [selectedIds, setSelectedIds] = React.useState(props.selectedIds);
  const [isActive, setIsActive] = React.useState('');

  const [openStatusConfirm, setOpenStatusConfirm] = React.useState(false);
  const refresh = useRefresh();
  const notify = useNotify();
  const unselectAll = useUnselectAll('users');
  const {data: identity} = useGetIdentity();

  const [updateManyWithStatus, {isLoading: loadingManyWithStatus}] = useUpdateMany(
    'users',
    {
      ids: selectedIds,
      data: {isActive},
    },
    {
      onSuccess: () => {
        refresh();
        notify('Status updated');
        unselectAll();
      },
      onError: error => notify(`Error: status not updated with status: "${isActive}".`, {type: 'warning'}),
    },
  );

  const handleStatusDialogClose = () => setOpenStatusConfirm(false);

  const handleConfirmForStatus = () => {
    updateManyWithStatus();
    setOpenStatusConfirm(false);
  };

  React.useEffect(() => {
    setIsActive('');
    setSelectedIds(props.selectedIds);
  }, [props.selectedIds, selectedIds]);

  const handleChange = (event: any) => {
    setOpenStatusConfirm(true);
    setIsActive(event.target.value);
  };
  const translate = useTranslate();

  const includesMe = selectedIds.some(id => identity?.id === id);

  return (
    <Fragment>
      { !includesMe && (
        <FormControl style={{position: 'relative', top: '-8px', minWidth: '120px'}}>
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">
            {translate('ra.user.setStatus')}
          </InputLabel>
          <Select value={isActive} onChange={handleChange} displayEmpty>
            <MenuItem value="" disabled>
              <em>{translate('ra.user.selectOne')}</em>
            </MenuItem>
            <MenuItem value="true" selected>
              {translate('ra.user.active')}
            </MenuItem>
            <MenuItem value="false">{translate('ra.user.disabled')}</MenuItem>
          </Select>
        </FormControl>
      )}
      <Confirm
        isOpen={openStatusConfirm}
        loading={loadingManyWithStatus}
        title={translate('ra.user.confirmationStatus')}
        content={translate('ra.user.confirmationContent', {
          isActive: isActive ? translate('ra.user.active') : translate('ra.user.disabled'),
        })}
        onConfirm={handleConfirmForStatus}
        onClose={handleStatusDialogClose}
      />
      { !includesMe ? (
        <BulkDeleteWithConfirmButton {...props} />
      ) : (
        <span>{translate('ra.user.disallowedForCurrentAdmin')}</span>
      )}
    </Fragment>
  );
};

const UserFilter = props => {
  return (
    <Filter {...props}>
      <SearchInput variant={'standard'} source="q" alwaysOn resettable />
      <NullableBooleanInput source="isActive" nullLabel="All" />
    </Filter>
  );
};

export const UserList = () => {
  const translate = useTranslate();

  return (
    <List sort={{field: 'lastName', order: 'ASC'}} exporter={false} filters={<UserFilter />}>
      <Datagrid className="oddRow" rowClick="edit" expand={UserEdit} bulkActionButtons={<UserBulkActionButtons />}>
        <TextField source="firstName" />
        <TextField source="lastName" />
        <EmailField source="email" />
        <FunctionField source="role" render={(record) => translate(`resources.users.fields.${record.role}`)}/>
        <BooleanField source="isActive" />
        <DateField source="createdAt" options={{day: '2-digit', month: 'short', year: 'numeric'}} />
        <DateField source="updatedAt" options={{day: '2-digit', month: 'short', year: 'numeric'}} />
      </Datagrid>
    </List>
  );
};
