import * as React from 'react';
import {Card, CardContent} from '@mui/material';
import {Title} from 'react-admin';

export const HelpPage = () => {
  return (
    <Card>
      <Title title="Help" />
      <CardContent>
        <h1>Helppp</h1>
        <p>
          Lorem, ipsum dolor sit amet consectetur adipisicing elit. Nisi nesciunt, quas repellat architecto iusto
          dignissimos ipsa obcaecati ipsam magni blanditiis. Odit nesciunt voluptatum blanditiis enim magni ducimus
          tempora consectetur itaque?
        </p>
      </CardContent>
    </Card>
  );
};

export default HelpPage;
