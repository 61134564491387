import {defaultTheme} from 'react-admin';
import {Theme} from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const colors = {
  primary: '#C09',
  secondary: '#3CC',
};
const font = {
  family: '"IBM Plex Sans", "-apple-system", "BlinkMacSystemFont", "Segoe UI", "Arial", "sans-serif"',
};

export const dvzTheme = {
  ...defaultTheme,
  palette: {
    ...defaultTheme.palette,
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    background: {
      default: '#ebebeb',
    },
  },
  typography: {
    ...defaultTheme.typography,
    fontFamily: font.family,
  },
  components: {
    ...defaultTheme.components,

    // React Admin components (needs to use selectors from root)
    RaMenuItemLink: {
      styleOverrides: {
        root: {
          '&& > .MuiSvgIcon-root': {
            marginLeft: 'auto',
          },
          '&.RaMenuItemLink-active': {
            color: colors.secondary,
            fontWeight: '800',
          },
        },
      },
    },
    RaDatagrid: {
      styleOverrides: {
        root: {
          '& .RaDatagrid-headerCell': {
            backgroundColor: '#888888',
            color: '#fff',
            fontWeight: '800',
          },
          '& .RaDatagrid-rowOdd': {
            backgroundColor: '#f3f3f3',
          },
        },
      },
    },
    RaSidebar: {
      styleOverrides: {
        root: {
          backgroundColor: '#333',
          color: '#fff',

          '& .RaSidebar-fixed': {
            backgroundColor: '#333',
            paddingTop: '48px',
            top: 0,
            height: '100vh',
          },
        },
      },
    },
    RaLayout: {
      styleOverrides: {
        root: {
          '& .RaLayout-content': {
            paddingTop: '48px',
            paddingLeft: '24px !important',
          },
          '& .RaLayout-appFrame': {
            marginTop: '0px!important',
          },
        },
      },
    },
    RaBulkActionsToolbar: {
      styleOverrides: {
        root: {
          '& .RaBulkActionsToolbar': {
            backgroundColor: '#fff',
          },
          '& .RaBulkActionsToolbar-topToolbar': {
            alignItems: 'center',
          },
        },
      },
    },

    // Material UI components (can access elements directly)
    MuiAppBar: {
      styleOverrides: {
        colorSecondary: {
          backgroundColor: '#505050',
          color: 'white',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          borderBottom: 'none',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle1: {
          fontWeight: 'bold',
        },
      },
    },
    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          '&$active': {
            color: '#fff',
            '&& $icon': {
              opacity: 1,
              color: '#fff',
              '&:hover': {
                color: '#ccc',
              },
            },
            '&:hover': {
              color: '#ccc',
            },
          },
          '&:hover': {
            color: '#fff',
            textDecoration: 'underline',
          },
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          justifyContent: 'space-between',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: '56px',
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined' as const,
      },
    },
    MuiFormControl: {
      defaultProps: {
        variant: 'outlined' as const,
      },
      styleOverrides: {
        root: {
          marginTop: '16px',
        },
      },
    },
  },
};
