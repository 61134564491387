import * as React from 'react';
import {Create, SimpleForm, TextInput, BooleanInput, ReferenceInput, SelectInput, required} from 'react-admin';
import {CustomToolbar} from '../../components/customtoolbar/customToolbar.component';
import {Box, Grid} from '@mui/material';

export const RiskCreate = () => {
  return (
    <Create>
      <SimpleForm toolbar={<CustomToolbar noDelete />}>
        <Grid container width="100%" spacing={1}>
          <Grid item xs={8}>
            <Box display="flex">
              <Box flex={1} mr={1}>
                <TextInput source="title" fullWidth />
              </Box>
              <Box flex={1} ml={1}>
                <ReferenceInput source="riskTemplateId" reference="riskTemplates">
                  <SelectInput optionText="title" validate={[required()]} />
                </ReferenceInput>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <BooleanInput source="startActive" />
      </SimpleForm>
    </Create>
  );
};
