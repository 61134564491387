import {Typography} from '@mui/material';
import {Show, SimpleShowLayout, useRecordContext, useResourceContext, useShowContext, useTranslate} from 'react-admin';

const OperationDocument = ({step}: {step: string}) => {
  const {record} = useShowContext();
  const hasWorkPermit = record?.workPermit?.status === 'done';
  const data = record?.[step];
  const t = useTranslate();

  return (
    <div>
      <Typography variant="h6">{t(`resources.operations.fields.${step}`)}</Typography>
      <Typography variant="body2">
        {hasWorkPermit ? (
          data?.fullDocumentUrl ? (
            <a href={data.fullDocumentUrl} target="_blank" rel="noreferrer">
              {data.documentFilename}
            </a>
          ) : (
            <span>{t('resources.operations.fields.pendingDocument')}</span>
          )
        ) : (
          <span>{t('resources.operations.fields.pendingWorkPermit')}</span>
        )}
      </Typography>
    </div>
  );
};

export const OperationShow = () => {
  const resource = useResourceContext();
  const record = useRecordContext();

  return (
    <Show resource={resource} id={record?.id}>
      <SimpleShowLayout>
        <OperationDocument step="technicianRiskAssessment" />
        <OperationDocument step="technicianWorkReport" />
      </SimpleShowLayout>
    </Show>
  );
};
