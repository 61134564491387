// import "@react-page/editor/lib/index.css";
// import swedishMessages from "@react-admin/react-admin-language-pack";
import * as React from 'react';
import {Admin, fetchUtils} from 'react-admin';
import polyglotI18nProvider from 'ra-i18n-polyglot';

import inMemoryJWT from './inMemoryJWT';
import simpleRestProvider from './simple-rest-provider';
import authProvider from './authProvider';
import routes from './routes';

import {Dashboard} from './components/dashboard/dashboard.component';
import languageSwedish from './components/language/swedish';
import LoginPage from './pages/login.page';
import Layout from './layouts/layout';

//import swedishMessages from '@react-admin/react-admin-language-pack';
//import polyglotI18nProvider from 'ra-i18n-polyglot';
import {dvzTheme} from './theme';

const apiUrl = process.env.REACT_APP_API_URL;
const apiAuth = process.env.REACT_APP_API_AUTH;

const httpClient = (url, opt = {}) => {
  const options = {
    ...opt,
    headers: new Headers({Accept: 'application/json'}),
  };
  const token = inMemoryJWT.getToken();

  if (token) {
    options.headers.set('Authorization', `Bearer ${token}`);
    return fetchUtils.fetchJson(url, options);
  } else {
    inMemoryJWT.setRefreshTokenEndpoint(`${apiAuth}/refresh-token-admin`);
    return inMemoryJWT.getRefreshedToken().then(gotFreshToken => {
      if (gotFreshToken) {
        options.headers.set('Authorization', `Bearer ${inMemoryJWT.getToken()}`);
      }
      return fetchUtils.fetchJson(url, options);
    });
  }
};

const dataProvider = simpleRestProvider(apiUrl ?? '', httpClient);

const messages = {
  sv: {
    // ...swedishMessages,
    ...languageSwedish,
  },
};
// @ts-ignore
const i18nProvider = polyglotI18nProvider(() => messages.sv);

const App = () => (
  <Admin
    title="Admin"
    i18nProvider={i18nProvider}
    authProvider={authProvider}
    dataProvider={dataProvider}
    dashboard={Dashboard}
    layout={Layout}
    disableTelemetry={true}
    theme={dvzTheme}
    loginPage={LoginPage}>
    {permissions => routes(permissions)}
  </Admin>
);

export default App;
